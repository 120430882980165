import React, { FunctionComponent, ReactElement, useRef, useState } from "react"
import i18next from "i18next"
import styled from "styled-components"
import { Description, IDescription } from "../layout/Description"
import { Column, ILayout, Layout, Row } from "../layout/Layout"
import { BreakPoints } from "../layout/responsive"
import { media } from "../layout/theme"
import { Icon, IIcon } from "../core/components/Icon"
import { colors } from "../core/colors"
import { useDropDownAnimation } from "../core/hooks/useDropDownAnimation"
import { useOnClickOutside } from "../core/hooks/useOnClickOutside"
import { getLocalesBg } from "../core/helpers"
import { BorderBottomAnimationWrapper } from "../core/components/BorderBottomAnimationWrapper"
import {
  AlignItemsTypes,
  DirectionTypes,
  JustifyContentTypes,
} from "../core/enums"
import { IconTypes } from "../core/Icons"
import { LocaleArrow } from "./LocaleArrow"

interface ILocalesText extends IDescription {
  short: boolean
}

interface IStyledLanguages extends ILayout {
  animationType: string
}

const LocalesText = styled(Description)<ILocalesText>`
  display: ${({ short }: ILocalesText): string => (short ? "none" : "flex")};
`

export const StyledLocales = styled(Layout)<ILayout>`
  height: 60px;
  padding: 0 10px 0 20px;
  cursor: pointer;
  ${media.lessThan(BreakPoints.desktop)} {
    margin: 0 10px;
  }
`

const LocaleIcon = styled(Icon)<IIcon>`
  ${media.lessThan(BreakPoints.phone)} {
    width: 18px;
    height: 18px;
  }
`

export const StyledLanguages = styled(Column)<IStyledLanguages>`
  ${({ animationType }: IStyledLanguages): string => animationType}
  position: absolute;
  z-index: 5;
  top: 60px;
  left: 0;
`

interface ISelectLanguage extends ILayout {
  color: string
}

const SelectLanguage = styled(Row)<ISelectLanguage>`
  color: ${({ color }: ISelectLanguage): string => color};
  text-transform: uppercase;
  cursor: pointer;
  padding: 15px 0;
  &:hover {
    color: ${colors.yellow};
  }
`

export interface ILocaleLink {
  code: string
  name: string
  path: string
}

export interface ILocales {
  locales: ILocaleLink[]
  short?: boolean
  footer?: boolean
}

const Locales: FunctionComponent<ILocales> = ({
  locales,
  short = true,
  footer = false,
}: ILocales): ReactElement => {
  const [visible, setVisible] = useState<boolean>(false)
  const animationType = useDropDownAnimation(visible)
  const ref = useRef(null)
  useOnClickOutside(ref, (): void => setVisible(false))

  const changeLocale = async (locale: ILocaleLink): Promise<void> => {
    // TODO: avoid page refresh
    // await i18next.changeLanguage(locale.code)
    setVisible(false)
    window.location.href = locale.path
  }

  const localesBg = getLocalesBg(visible, footer)
  return (
    <Row componentWidth="auto">
      <BorderBottomAnimationWrapper removeBorder={visible}>
        <StyledLocales
          bg={localesBg}
          ai={AlignItemsTypes.center}
          componentWidth="auto"
          onClick={(): void => setVisible(!visible)}
          layoutRef={ref}
          direction={DirectionTypes.row}
        >
          {i18next.language.toUpperCase()}
          {!short && (
            <LocalesText short={short} margin="0 15px 0 10px" uppercase>
              languages
            </LocalesText>
          )}
          <LocaleArrow short={short} />
          <StyledLanguages bg={colors.dark} animationType={animationType}>
            {animationType &&
              locales.map((locale: ILocaleLink): ReactElement => {
                const selected = i18next.language === locale.code
                const color = selected ? colors.yellow : colors.lightText

                return (
                  <SelectLanguage
                    key={locale.code}
                    jc={JustifyContentTypes.center}
                    ai={AlignItemsTypes.center}
                    onClick={(): Promise<void> => changeLocale(locale)}
                    color={color}
                  >
                    {locale.code}
                  </SelectLanguage>
                )
              })}
          </StyledLanguages>
        </StyledLocales>
      </BorderBottomAnimationWrapper>
    </Row>
  )
}

export { Locales }
