import React, { ReactElement } from "react"
import styled from "styled-components"
import { IHeaderLink } from "./headerLinks"
import { LayoutTags, Row } from "../layout/Layout"
import { AlignItemsTypes } from "../core/enums"
import { TopBarMenuItem } from "./TopBarMenuItem"

const StyledList = styled.ul`
  display: flex;
  width: auto;
  align-items: center;
  height: 100%;
`

export interface IRenderLink {
  channel?: string
  config: IHeaderLink[]
  locale?: string
}

export const RenderLinks = ({ config, locale }: IRenderLink): ReactElement => {
  return (
    <Row
      tagName={LayoutTags.nav}
      componentWidth="auto"
      componentHeight="100%"
      ai={AlignItemsTypes.center}
    >
      <StyledList>
        {config.map(
          (link, index): ReactElement => (
            <TopBarMenuItem key={index} data={link} locale={locale} />
          )
        )}
      </StyledList>
    </Row>
  )
}
