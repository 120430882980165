export interface FormErrors {
  email?: boolean
  password?: boolean
  age?: boolean
  tos?: boolean
}

export interface PassValidation {
  length?: boolean
  upper?: boolean
  lower?: boolean
  special?: boolean
}

export function validate(email: string, password: string, age: string, tos: boolean): FormErrors {
  const e = validateEmail(email)
  const p = validatePassword(password)
  const a = validateAge(parseInt(age))
  const t = validateTos(tos);
  return {email: !e, password: !p, age: !a, tos: !t};
}

export function validateEmail(value: string) {
  // Email reg exp
  return new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$").test(
    value
  );
}

export function validateAge(value: number) {
  return value && value > 0;
}

export function validatePassword(value: string) {
  const min = 8;
  const max = 32;
  const isMoreThanMin = value.length > min - 1;
  const isLessThanMax = value.length < max - 1;
  const hasUppercase = new RegExp(/(.*[A-Z].*)/).test(value);
  const hasLowercase = new RegExp(/(.*[a-z].*)/).test(value);
  const hasSpecialChapter = new RegExp(/(?=.*[-+_!@#$%^&*., ?])/).test(value);

  return (
    isMoreThanMin &&
    isLessThanMax &&
    hasUppercase &&
    hasLowercase &&
    hasSpecialChapter
  );
}

export function passwordValidation(value: string): PassValidation {
  const min = 8;
  const max = 32;
  const isMoreThanMin = value.length > min - 1;
  const isLessThanMax = value.length < max - 1;
  const hasUppercase = new RegExp(/(.*[A-Z].*)/).test(value);
  const hasLowercase = new RegExp(/(.*[a-z].*)/).test(value);
  const hasSpecialChapter = new RegExp(/(?=.*[-+_!@#$%^&*., ?])/).test(value);

  return {
    length: !isMoreThanMin || !isLessThanMax,
    upper: !hasUppercase,
    lower: !hasLowercase,
    special: !hasSpecialChapter
  };
}

export function validateTos(checked: boolean) {
  return checked;
}
