import React, { FunctionComponent, ReactElement } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { DownloadIcon } from "./DownloadIcon"
import { Button, EButtonAnimationTypes, IButton } from "../form/Button"
import { media } from "../layout/theme"
import { BreakPoints } from "../layout/responsive"
import { colors } from "../core/colors"
import { Description, IDescription } from "../layout/Description"
import { ComponentSizesTypes, WeightTypes } from "../core/enums"

const StyledButton = styled(Button)<IButton>`
  min-width: 230px;
  a {
    border-radius: 0;
    height: 60px;
  }
  ${media.lessThan(BreakPoints.smallDesktop)} {
    a {
      min-width: auto;
      border-radius: 0;
      height: 60px;
    }
  }
`

export enum DownloadGlyphType {
  light = "light",
  dark = "dark",
}

interface IDownloadGlyph {
  type?: DownloadGlyphType
}

const DownloadGlyphColors = {
  light: { main: colors.yellow, text: colors.dark },
  dark: { main: colors.dark, text: colors.yellow },
}

const DesktopDownloadText = styled(Description)<IDescription>`
  letter-spacing: 0.49px;
  font-size: 17px;
  display: flex;
  text-transform: uppercase;
  ${media.lessThan(BreakPoints.smallDesktop)} {
    display: none;
  }
`

const TabletDownloadText = styled(Description)<IDescription>`
  letter-spacing: 0.49px;
  display: none;
  ${media.lessThan(BreakPoints.smallDesktop)} {
    font-size: 17px;
    display: flex;
  }
  ${media.lessThan(BreakPoints.tablet)} {
    display: none;
  }
`

const DownloadGlyph: FunctionComponent<IDownloadGlyph> = ({
  type = DownloadGlyphType.light,
}: IDownloadGlyph): ReactElement => {
  const { t } = useTranslation()
  const color = DownloadGlyphColors[type]

  const downloadLink =
    "https://glyph.dyn.triongames.com/glyph/live/GlyphInstall.exe"

  return (
    <StyledButton
      animationType={EButtonAnimationTypes.primary}
      tagName="a"
      href={downloadLink}
      componentSize={ComponentSizesTypes.auto}
      padding="0 35px"
      color={color.main}
    >
      <DownloadIcon color={color.text} />
      <DesktopDownloadText
        weight={WeightTypes.w600}
        mleft="10px"
        color={color.text}
      >
        {t("Install Glyph")}
      </DesktopDownloadText>
      <TabletDownloadText
        weight={WeightTypes.w600}
        mleft="10px"
        color={color.text}
      >
        {t("Install")}
      </TabletDownloadText>
    </StyledButton>
  )
}

export { DownloadGlyph }
