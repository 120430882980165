import React, { ReactElement } from "react"
import styled from "styled-components"
import { SearchIcon } from "./SearchIcon"
import { IGame } from "../core/misk"
import { Button, IButton } from "../form/Button"
import { colors } from "../core/colors"

interface ISearch {
  onClick: () => void
  game?: IGame
}

const ButtonContainer = styled(Button)<IButton>`
  width: 45px;
  button {
    height: 40px;
    border-bottom: 1px solid ${colors.yellow};
  }
`
const Search = ({ onClick, game }: ISearch): ReactElement => {
  return (
    <ButtonContainer onClick={onClick} color={colors.yellow}>
      {game ? (
        <img src={game.mobileImage} alt={game.mobileImage} />
      ) : (
        <SearchIcon color={colors.dark} />
      )}
    </ButtonContainer>
  )
}

export { Search }
