import React from "react"
import {
  CloseButton as CloseButtonElement,
  ICloseButton,
} from "./close-button-elements"

interface CloseButtonProps extends ICloseButton {
  onClose?: (...args: any) => void
}

const CloseButton = ({ onClose, ...elementProps }: CloseButtonProps) => {
  return <CloseButtonElement onClick={onClose} {...elementProps} />
}

export { CloseButton }
