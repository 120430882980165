import { useEffect, useState } from "react"
import { callScaleYClose, callScaleYOpen } from "../animations"

function useDropDownAnimation(visible: boolean): string {
  // This value to check is visible are changed from default value "false" to don't run animation on the component
  // first render
  const [hasChanged, changeHasChanged] = useState<boolean>(visible)
  // Animation type contains the animation call "string" by component visibility
  const [animationType, setAnimationType] = useState<string>("")

  // Condition to detect is visible has changed from the default "false" value to smth else. Only for hasChange = false
  if (hasChanged !== visible && !hasChanged) {
    changeHasChanged(true)
  }

  useEffect((): void => {
    // Call getting animation type method only after first render state changes
    if (hasChanged) {
      setAnimationType(visible ? callScaleYOpen() : callScaleYClose())
    }
  }, [visible])

  return animationType
}

export { useDropDownAnimation }
