import styled from "styled-components"
import React, { ReactElement } from "react"
import { INavLink, NavLink } from "../core/components/NavLink"
import { ILayout, LayoutTags, Row } from "../layout/Layout"
import { fontSize, media } from "../layout/theme"
import { BreakPoints } from "../layout/responsive"
import {
  AlignItemsTypes,
  FontSizeTypes,
  JustifyContentTypes,
} from "../core/enums"
import { colors } from "../core/colors"
import { BorderBottomAnimationWrapper } from "../core/components/BorderBottomAnimationWrapper"

interface IStyledLinkNext extends INavLink {
  active: boolean
}

const StyledLinkContainer = styled(Row)<ILayout>`
  margin: 0 15px;
  ${media.lessThan(BreakPoints.desktop)} {
    margin: 0 8px;
  }
`

const StyledLinkNext = styled(NavLink)<IStyledLinkNext>`
  &:hover {
    opacity: 1;
  }
  ${media.lessThan(BreakPoints.desktop)} {
    ${fontSize({ fontSize: FontSizeTypes.s })}
  }
  border-bottom-color: ${(props: IStyledLinkNext): string =>
    props.active ? colors.yellow : "transparent"};
  white-space: nowrap;
`

export interface ILinkItem {
  to: string
  text: string
  locale?: string
}

export function ListItem({ to, text, locale }: ILinkItem): ReactElement {
  // TODO const { t } = useTranslation(Namespaces.common);
  // TODO const { pathname } = useRouter();
  // TODO const active = pathname === to;
  const active = false

  return (
    <StyledLinkContainer
      tagName={LayoutTags.li}
      componentHeight="100%"
      jc={JustifyContentTypes.center}
      ai={AlignItemsTypes.center}
      padding="0 8px"
    >
      <BorderBottomAnimationWrapper>
        <StyledLinkNext active={active} href={to} locale={locale}>
          {text.toUpperCase()}
        </StyledLinkNext>
      </BorderBottomAnimationWrapper>
    </StyledLinkContainer>
  )
}
