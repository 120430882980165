import { ITopBarSubMenu } from "./headerLinks"
import { ILayout, LayoutTags, Row } from "../layout/Layout"
import { AlignItemsTypes, FontSizeTypes, JustifyContentTypes } from "../core/enums"
import { BorderBottomAnimationWrapper } from "../core/components/BorderBottomAnimationWrapper"
import React from "react"
import styled from "styled-components"
import { fontSize, media } from "../layout/theme"
import { BreakPoints } from "../layout/responsive"
import { colors } from "../core/colors"

const StyledLinkContainer = styled(Row)<ILayout>`
  margin: 0 15px;
  ${media.lessThan(BreakPoints.desktop)} {
    margin: 0 8px;
  }
  .sub-menu {
    display: none;
  }
  &:hover {
    .sub-menu {
      display: flex;
    }
  }
`

const StyledLinkNext = styled.div`
  &:hover {
    opacity: 1;
  }
  ${media.lessThan(BreakPoints.desktop)} {
    ${fontSize({ fontSize: FontSizeTypes.s })}
  }
  border-bottom-color: ${colors.yellow};
  white-space: nowrap;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: default;
`

interface Props {
  subMenu: ITopBarSubMenu[]
  text: string
  locale?: string
}

const SubMenu = styled.div`
  display: flex;
  height: 60px;
  width: 100vw;
  position: fixed;
  align-items: center;
  padding-left: 186px;
  top: 60px;
  left: 0;
  z-index: 1;
  background: ${colors.purpleMain};
`;

const SubMenuItem = styled.a`
  margin: 0 20px;
  font-size: 14px;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 3px solid transparent;
  &:hover {
      border-bottom: 3px solid ${colors.yellow};
  }
`;

interface SubMenuTextProps {
  withImage: boolean
}
const SubMenuText = styled.span<SubMenuTextProps>`
  font-size: ${props => props.withImage ? '12px' : '1rem'};
  margin-top: ${props => props.withImage ? '6px' : '0'};
  padding-top: ${props => props.withImage ? '0' : '4px'};
`;
const SubMenuImg = styled.img`
  max-height: 25px;
  height: auto;
  width: auto;
`

export function ListItemWithSub({subMenu, text, locale}: Props) {
  return <StyledLinkContainer
    tagName={LayoutTags.li}
    componentHeight="100%"
    jc={JustifyContentTypes.center}
    ai={AlignItemsTypes.center}
    padding="0 8px"
  >
    <BorderBottomAnimationWrapper>
      <StyledLinkNext>
        {text.toUpperCase()}
        <SubMenu className="sub-menu">
          {subMenu.map(
            (item, index) => <SubMenuItem
              target={item.link.target === 'sameWindow' ? '_self' : '_blank'}
              href={item.link.url}
              hrefLang={locale}
              key={index}
            >
              {item.asset && <SubMenuImg alt={item.asset?.name ? item.asset?.name : ""} src={item.asset?.url} />}
              <SubMenuText withImage={!!item.asset}>{item.text}</SubMenuText>
            </SubMenuItem>
          )}
        </SubMenu>
      </StyledLinkNext>
    </BorderBottomAnimationWrapper>
  </StyledLinkContainer>;
}
