import React, { ReactNode } from "react"
import { ILink } from "../../../gatsby-graphql"
import {
  Button as StyledButton,
  ButtonSize,
  ButtonType,
} from "./button-elements"

interface ButtonProps {
  to?: string
  link?: ILink
  type?: ButtonType
  size?: ButtonSize
  textTransform?: string
  children?: ReactNode
  onClick?: () => void
  locale?: string
}

const Button = ({
  to,
  link,
  locale = "",
  type = "primary",
  size = "md",
  textTransform = "",
  onClick,
  children,
}: ButtonProps) => {
  return (
    <StyledButton
      to={to}
      link={link}
      locale={locale}
      type={type}
      size={size}
      textTransform={textTransform}
      className={`btn ${type}`}
      onClick={onClick}
    >
      {children}
    </StyledButton>
  )
}

export { Button }
