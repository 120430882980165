import styled from "styled-components"
import { BreakPoints, media } from "../../helpers/rwd"
import { ContentWrapperStyles } from "./content-wrapper-styles"

interface IContentWrapperProps {
  maxWidth?: number
}

export const ContentWrapper = styled.div<IContentWrapperProps>`
  margin: 0 auto;
  width: 100%;
  max-width: 1250px;
  padding: 0 30px;

  ${media.max(BreakPoints.lgDesktop)} {
    max-width: 1083px;
  }
  ${media.max(BreakPoints.desktop)} {
    max-width: 889px;
  }
  ${media.max(BreakPoints.smallDesktop)} {
    max-width: 645px;
  }
  ${media.max(BreakPoints.phone)} {
    padding: 0 20px;
  }
  ${ContentWrapperStyles}
  ${props =>
    props.maxWidth &&
    `
    max-width: ${props.maxWidth}px;
  `}
`
