import styled from "styled-components"
import { FunctionComponent, ReactElement, useState } from "react"
import * as React from "react"
import { Search } from "./Search"
import { Burger } from "./Burger"
import { MenuDrawer } from "./MenuDrawer"
import { MenuPosition, MobileMenu } from "./MobileMenu"
import { MobileStoreMenu } from "./MobileStoreMenu"
import { MobileSearch } from "./MobileSearch"
import { Category, IGame } from "../core/misk"
import { Column, ILayout, Layout, Row } from "../layout/Layout"
import { BreakPoints } from "../layout/responsive"
import { media } from "../layout/theme"
import { colors } from "../core/colors"
import { AlignItemsTypes, JustifyContentTypes } from "../core/enums"
import { NavLink } from "../core/components/NavLink"
import { Logo } from "../common/Logo"
import { ILocaleLink } from "../common/Locales"
import { GatsbyImage } from "gatsby-plugin-image"
import { FTopBarFragment } from "../../../../gatsby-graphql"

interface MobileHeader {
  data: FTopBarFragment
  games: IGame[]
  channel?: string
  categories: Category[]
  locales?: ILocaleLink[]
  showSearch?: boolean
}

const LogoContainer = styled(Row)<ILayout>`
  & a {
    border: 0;
  }
`

const StyledMobileHeader = styled(Column)<ILayout>`
  display: none;
   ${media.lessThan(BreakPoints.desktop)} {
     display: flex;
   }
`

const MobileHeader: FunctionComponent<MobileHeader> = ({
  data,
  games,
  channel,
  categories,
  locales = [],
  showSearch = true,
}: MobileHeader): ReactElement => {
  const [menu, setMenu] = useState<boolean>(false)
  const [search, setSearch] = useState<boolean>(false)
  const currentGame = games.find(
    (game: IGame): boolean => game.channel === channel
  )

  const logoHeight = 40;
  let logoWidth = data.logo?.localFile?.childImageSharp?.gatsbyImageData?.width / data.logo?.localFile?.childImageSharp?.gatsbyImageData?.height * logoHeight
  if (isNaN(logoWidth)) {
    logoWidth = 0;
  }
  return (
    <StyledMobileHeader componentHeight="40px">
      <Row
        componentHeight="40px"
        bg={colors.dark}
        ai={AlignItemsTypes.center}
        jc={JustifyContentTypes.spaceBetween}
      >
        {showSearch && (
          <Row componentWidth="auto" componentHeight="100%">
            <Search onClick={(): void => setSearch(true)} game={currentGame} />
          </Row>
        )}
        <LogoContainer
          componentHeight="40px"
          padding="8px 0"
          jc={JustifyContentTypes.center}
          ai={AlignItemsTypes.center}
        >
          <NavLink href="/">
            <Layout componentHeight={logoHeight+'px'} componentWidth={logoWidth+'px'} jc={JustifyContentTypes.center}>
              <GatsbyImage alt={''} image={data.logo?.localFile?.childImageSharp?.gatsbyImageData} />
            </Layout>
          </NavLink>
        </LogoContainer>
        <Row componentWidth="auto" componentHeight="100%">
          <Burger onClick={(): void => setMenu(true)} />
        </Row>
      </Row>
      {menu && (
        <MenuDrawer
          position={MenuPosition.right}
          onClose={(): void => setMenu(false)}
        >
          <MobileMenu data={data} locales={locales} />
        </MenuDrawer>
      )}
    </StyledMobileHeader>
  )
}

export { MobileHeader }
