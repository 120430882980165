import styled, { css } from "styled-components"
import {
  buttonStyles,
  CheckboxContainerLabelStyles,
  CheckboxInputLabelAfterStyles,
  CheckboxLabelBeforeStyles,
  CheckboxLinkStyles, ErrorColor,
  errorStyles,
  inputStyle,
  labelStyles,
  PassErrorTitleStyles,
  PasswordErrorsListStyles,
  RegisterFormStyles,
  StatusWrapperStyles, SuccessColor
} from "./registration-form-styles"

const flex = css`
   display: flex;
   width: 100%;
   box-sizing: border-box;
   flex-direction: column;
   position: relative;
`

const input = css`
   width: 100%;
   display: block;
   padding: 15px 20px;
   margin-top: 10px;
   ${inputStyle}
`

const error = css`
   z-index: 1;
   font-size: 13px;
   ${errorStyles}
`

const button = css`
  width: 100%;
  max-width: 323px;
  height: 60px;
  text-transform: uppercase;
  cursor: pointer;
  padding: 5px 10px;
  ${buttonStyles}
`

const label = css`
  ${labelStyles}
`

const RegFormGroupCss = css`
   margin-bottom: 47px;
   flex-direction: row;
   justify-content: center;
   @media screen and (max-width: 479px) {
     margin-bottom: 38px;
   }
`

export const StatusWrapper = styled.div`
  display: flex;
  justify-content: center;
  ${StatusWrapperStyles}
`;

export const RegisterForm = styled.div`
   opacity: 1;
   padding: 46px 55px;
   position: relative;
   max-width: 515px;
   margin-left: auto;
   margin-right: auto;
   max-height: 100%;
   overflow: auto;
   @media screen and (max-width: 479px) {
     padding: 30px 25px;
   }
   ${RegisterFormStyles}
`

export const Form = styled.form`
  ${flex}
`

export const Wrapper = styled.div`
  ${flex}
`

export const RegFormGroup = styled.div`
   ${RegFormGroupCss}
`

export const Label = styled.label`
  ${label}
`

export const RegistrationInput = styled.input`
  ${input}
`

export const EmailError = styled.span`
  position: relative;
  top: 10px;
  ${error}
`;

export const PasswordError = styled.span`
  position: relative;
  top: 10px;
  ${error}
`

export const PassFormGroup = styled.div`
  ${flex}
  ${RegFormGroupCss}
  justify-content: space-between;
`

export const PasswordContainer = styled.div`
  ${flex}
  flex-direction: row;
  align-items: flex-end;
  width: 70%;
`

export const AgeContainer = styled.div`
   width: 20%;
`

export const Age = styled.input`
  ${input}
  padding: 15px 3px 15px 20px;
`;

export const PasswordErrors = styled.div`
   position: absolute;
   left: 0;
   top: 110%;
   z-index: 10;
  background: #0C0913;
`

interface PassErrorTitleProp {
  hasError: boolean
}

export const PassErrorTitle = styled.span<PassErrorTitleProp>`
  display: block;
  padding: 2% 5%;
  background-color: ${prop => prop.hasError ? ErrorColor : SuccessColor};
  ${PassErrorTitleStyles}
`

export const PasswordErrorsList = styled.ul`
   display: block;
   padding: 2% 5%;
  list-style: none;
   ${PasswordErrorsListStyles}
`

export const CheckboxContainer = styled.div`
  flex-direction: row;
  align-items: center;
  margin-bottom: 25px;
`

export const TosError = styled.span`
  left: 30px;
  position: relative;
  bottom: 0;
  ${error}
`

export const RegSubmitContainer = styled.div`
  align-items: center;
  ${flex}
`

export const RegistrationButton = styled.button`
  position: relative;
  ${button}
`

export const RegDownloadWrapper = styled.div`
   display: flex;
   justify-content: center;
`

export const RegDownloadGlyph = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  ${button}
`

export const CheckboxInput = styled.input`
  display: none;
  &:checked + label::after {
    content: "";
    position: absolute;
    left: 6px;
    top: 1px;
    width: 4px;
    height: 9px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    ${CheckboxInputLabelAfterStyles}
  }
`

export const CheckboxLabel = styled.label`
   padding-left: 30px;
   margin: 0;
   line-height: inherit;
   font-size: 12px;
   ${label}
   &::before {
     content: "";
     position: absolute;
     left: 0;
     top: 0;
     width: 16px;
     height: 16px;
     ${CheckboxLabelBeforeStyles}
   }
`

export const CheckboxLink = styled.a`
   ${CheckboxLinkStyles}
`

export const CheckboxContainerLabel = styled.label`
  ${label}
  ${CheckboxContainerLabelStyles}
`

export const AgeError = styled.span`
   left: -116px;
   width: 200px;
   text-align: right;
   white-space: normal;
   ${error}
`

interface PassErrorMessageProps {
  isValid: boolean;
}
export const PassErrorMessage = styled.span<PassErrorMessageProps>`
  color: ${props => props.isValid ? SuccessColor : ErrorColor}
`

export const ImageWrapper = styled.div`
  height: 80px;
  display: flex;
  justify-content: center;
  margin-bottom: 45px;
`

export const RegHeader = styled.h2`
  text-align: center;
  margin-bottom: 55px;
`

export const TextContainer = styled.div`
  margin-top: 25px;
`
