import { css } from "styled-components"

const gold = "#e7c680"
export const colors = {
  blueGradientStart: "#000000",
  blueGradientEnd: "#434343",
  goldGradientStart: gold,
  goldGradientEnd: "#7e683e",
  goldGradientHoverStart: "#fae5b7",
  goldGradientHoverEnd: "#806A41",
  gold,
  dark: "#434343",
  purpleLight: "#1f1630",
  white: "#FFF",
  blue: "#000000",
  darkBlue: "#434343",
  darker: "#000d19",
  gray: "#aaa",
  darkGray: "#989898",
  purpleMain: "#2C1F42",
  lightGray: "#4f5c66",
  yellow: "#FFCB00",
  purple: "#201630",
  purpleDark: "#473F54",
  categoriesBarGradientStart: "rgba(18, 56, 76, 0.616)",
  categoriesBarGradientEnd: "#020b10",
  categoriesBarHover: "#70eafc",
  greenblue: "#00709B",
  orange: "#F2B75F",
}

export const blueEllipseGradient = css`
  background-image: radial-gradient(
    ellipse at center bottom,
    ${colors.blue} 10%,
    transparent 60%
  );
`
