import * as React from "react"
import { FunctionComponent, ReactElement, useState } from "react"
import styled from "styled-components"
import { Column, ILayout } from "../layout/Layout"
import { Button, EButtonAnimationTypes, IButton } from "../form/Button"
import { colors } from "../core/colors"
import { useAuth } from "../core/hooks/useAuth"
import { ComponentSizesTypes, FontSizeTypes } from "../core/enums"
import { Description } from "../layout/Description"
import Modal from "../../modal"
import RegistrationForm from "../../registration-form"

interface IStyledDropDown extends ILayout {
  animationType: string
}

const Register = styled(Button)<IButton>`
  border: 2px solid ${colors.yellow};
`

const LogOut = styled(Button)<IButton>`
  border: 1px solid ${colors.yellow};
`

const StyledDropDown = styled(Column)<IStyledDropDown>`
  ${({ animationType }: IStyledDropDown): string => animationType}
  position: absolute;
  left: 0;
  top: 60px;
  z-index: 5;
`

interface AccountDropDown {
  setVisible: (value: boolean) => void
  animationType: string
  shouldShowLogin: boolean
  openModal: (value: boolean) => void
}

const AccountDropDown: FunctionComponent<AccountDropDown> = ({
  setVisible,
  animationType,
  shouldShowLogin,
  openModal,
}: AccountDropDown): ReactElement | null => {
  // const router = useRouter()
  const { user, logout } = useAuth()
  const handleClick = async (url: string): Promise<void> => {
    // TODO if we don't want to open modal we need to use router, but this functionality not finished
    // TODO await router.push(url)
    openModal(true);
    setVisible(false);
  }

  const handleLogUt = async (): Promise<void> => {
    logout()
    setVisible(false)
    // TODO
    // await router.push("/")
  }

  // Default value for the first render it's empty string. Based on the default visibility.
  // Shouldn't render smth on the first render. Only for state change, which changing animation type by visible.
  // The component should be in the DOM. That because using return null, instead of do not render component in the parent call.
  if (!animationType) {
    return null
  }

  if (!user) {
    return (
      <StyledDropDown
        bg={colors.dark}
        padding="20px 15px"
        animationType={animationType}
      >
        {shouldShowLogin && <Button
          animationType={EButtonAnimationTypes.primary}
          componentSize={ComponentSizesTypes.full}
          onClick={(): Promise<void> => handleClick("/login")}
          height="45px"
          color={colors.yellow}
        >
          <Description fontSize={FontSizeTypes.m} color={colors.dark} uppercase>
            login
          </Description>
        </Button>}
        <Register
          animationType={EButtonAnimationTypes.topSlide}
          componentSize={ComponentSizesTypes.full}
          onClick={(): Promise<void> => handleClick("/register")}
          height="45px"
          mtop={shouldShowLogin ? "20px" : "0"}
          color={colors.dark}
        >
          <Description fontSize={FontSizeTypes.m} uppercase>
            register
          </Description>
        </Register>
      </StyledDropDown>
    )
  }

  return (
    <StyledDropDown
      bg={colors.dark}
      padding="20px 15px"
      animationType={animationType}
    >
      <Button
        componentSize={ComponentSizesTypes.full}
        onClick={(): Promise<void> => handleClick("/account/dashboard")}
        height="45px"
        color={colors.dark}
      >
        <Description fontSize={FontSizeTypes.m} uppercase>
          account
        </Description>
      </Button>
      <Button
        componentSize={ComponentSizesTypes.full}
        onClick={(): Promise<void> => handleClick("/account/code")}
        height="45px"
        mtop="20px"
        color={colors.dark}
      >
        <Description fontSize={FontSizeTypes.m} uppercase>
          apply code
        </Description>
      </Button>
      <LogOut
        animationType={EButtonAnimationTypes.topSlide}
        componentSize={ComponentSizesTypes.full}
        onClick={handleLogUt}
        height="45px"
        mtop="20px"
        color={colors.dark}
      >
        <Description fontSize={FontSizeTypes.m} uppercase>
          sign out
        </Description>
      </LogOut>
    </StyledDropDown>
  )
}

export { AccountDropDown }
