import React, { ReactNode } from "react"
import { Link as GatsbyLink } from "gatsby"
import { ILink } from "../../../gatsby-graphql"

export enum ELinkTargets {
  sameWindow = "sameWindow",
  newWindow = "newWindow",
  modalWindow = "modalWindow",
}

interface LinkProps {
  children?: ReactNode
  to?: string
  link?: ILink
  target?: string
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
  download?: boolean | string
  className?: string
  locale?: string
}

const Link = ({
  children,
  to,
  link,
  target,
  onClick,
  download,
                locale,
  className,
}: LinkProps) => {
  if (link) {
    to = link.url || `/${link.locale || "en"}/${link.slug}/`
    children = children || link.label
  }

  const getTarget = (target: string) => {
    switch (target) {
      case "newWindow":
        return "_blank"
      case "sameWindow":
      default:
        return target
    }
  }

  // check if internal
  if (to && /^\/(?!\/)/.test(to)) {
    return (
      <GatsbyLink
        to={to}
        target={getTarget(target || (link?.target as string))}
        onClick={onClick}
        download={download}
        className={className}
        hrefLang={locale ? locale : "en"}
      >
        {children}
      </GatsbyLink>
    )
  }
  return (
    <a
      href={to}
      target={getTarget(target || (link?.target as string))}
      onClick={onClick}
      download={download}
      className={className}
      hrefLang={locale ? locale : "en"}
    >
      {children}
    </a>
  )
}

export { Link }
