import React, { ReactElement, useState } from "react"
import styled from "styled-components"
import { ListItem } from "./MobileMenu"
import { Button, IButton } from "../form/Button"
import { colors } from "../core/colors"
import { useAuth } from "../core/hooks/useAuth"
import { Column } from "../layout/Layout"
import { ComponentSizesTypes } from "../core/enums"
import { Description } from "../layout/Description"
import RegistrationForm from "../../registration-form"
import Modal from "../../modal"

const LoginButton = styled(Button)<IButton>`
  border: 1px solid ${colors.yellow};
  button {
    height: 33px;
  }
`

const RegisterButton = styled(Button)<IButton>`
  border: 1px solid ${colors.yellow};
  button {
    height: 33px;
  }
`

interface IMobileAccount {
  shouldShowLogin?: boolean | null
}

const MobileAccount = ({shouldShowLogin}: IMobileAccount): ReactElement => {
  const { user, routerPush } = useAuth()
  const [openedModal, openModal] = useState(false);

  if (shouldShowLogin === null || shouldShowLogin === undefined) {
    shouldShowLogin = true;
  }

  if (!user) {
    return (
      <Column>
        {shouldShowLogin && <LoginButton
          onClick={(): void => routerPush("/login")}
          componentSize={ComponentSizesTypes.full}
          color={colors.yellow}
        >
          <Description color={colors.dark} uppercase>
            login
          </Description>
        </LoginButton>}
        <RegisterButton
          onClick={() => openModal(true)}
          componentSize={ComponentSizesTypes.full}
          mtop={shouldShowLogin ? "20px" : "0"}
          color={colors.dark}
        >
          <Description uppercase>register</Description>
        </RegisterButton>
        <Modal onClose={() => openModal(false)} opened={openedModal}>
          <RegistrationForm/>
        </Modal>
      </Column>
    )
  }

  // TODO user?.account?.email
  return (
    <Column>
      <ListItem pbottom="10px">
        <Description>{(user.account || { email: "" }).email}</Description>
      </ListItem>
      <LoginButton
        color={colors.yellow}
        mtop="20px"
        componentSize={ComponentSizesTypes.full}
        onClick={(): void => routerPush("/account/dashboard")}
      >
        <Description color={colors.dark} uppercase>
          my account
        </Description>
      </LoginButton>
    </Column>
  )
}

export { MobileAccount }
