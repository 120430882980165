import styled, { css } from "styled-components"
import { fonts } from "../../helpers/fonts"
import { BreakPoints, media } from "../../helpers/rwd"
import {
  HeadingCommonStyles,
  HeadingH1Styles,
  HeadingH2Styles,
  HeadingH3Styles,
  HeadingH4Styles,
  HeadingH5Styles,
  HeadingH6Styles,
} from "./heading-styles"

interface IHeading {
  textTransform?: string
}

const HeadingCommon = css<IHeading>`
  margin: 0.6em 0;
  line-height: 1.7em;
  ${media.max(BreakPoints.tablet)} {
    margin: 0.3em 0;
    line-height: 1.5em;
  }
  text-transform: ${props =>
    props.textTransform ? props.textTransform : "none"};
`

export const HeadingH1 = styled.h1<IHeading>`
  ${HeadingCommon}
  font-size: ${fonts.sizes[7]};
  ${media.max(BreakPoints.tablet)} {
    font-size: ${fonts.sizes[6]};
  }
  ${HeadingCommonStyles}
  ${HeadingH1Styles}
`

export const HeadingH2 = styled.h2<IHeading>`
  ${HeadingCommon}
  font-size: ${fonts.sizes[6]};
  ${media.max(BreakPoints.tablet)} {
    font-size: ${fonts.sizes[5]};
  }
  ${HeadingCommonStyles}
  ${HeadingH2Styles}
`

export const HeadingH3 = styled.h3<IHeading>`
  ${HeadingCommon}
  font-size: ${fonts.sizes[5]};
  ${media.max(BreakPoints.tablet)} {
    font-size: ${fonts.sizes[4]};
  }
  ${HeadingCommonStyles}
  ${HeadingH3Styles}
`

export const HeadingH4 = styled.h4<IHeading>`
  ${HeadingCommon}
  font-size: ${fonts.sizes[4]};
  ${media.max(BreakPoints.tablet)} {
    font-size: ${fonts.sizes[3]};
  }
  ${HeadingCommonStyles}
  ${HeadingH4Styles}
`

export const HeadingH5 = styled.h5<IHeading>`
  ${HeadingCommon}
  font-size: ${fonts.sizes[3]};
  ${media.max(BreakPoints.tablet)} {
    font-size: ${fonts.sizes[2]};
  }
  ${HeadingCommonStyles}
  ${HeadingH5Styles}
`

export const HeadingH6 = styled.h6<IHeading>`
  ${HeadingCommon}
  font-size: ${fonts.sizes[2]};
  ${media.max(BreakPoints.tablet)} {
    font-size: ${fonts.sizes[1]};
  }
  ${HeadingCommonStyles}
  ${HeadingH6Styles}
`
