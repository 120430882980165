import React, { PropsWithChildren } from "react"
import { createPortal } from "react-dom"
import { ModalWrapper, ModalContent } from "./modal-elements"
import { CloseButton } from "../../ui"

interface ModalProps extends PropsWithChildren<{}> {
  onClose?: (...args: any) => void
  opened?: boolean
}

const Modal = ({ onClose, opened, children }: ModalProps) => {
  if (!opened) {
    return null
  }

  return createPortal(
    <ModalWrapper>
      <ModalContent onClick={e => e.stopPropagation()}>
        {children}
        <CloseButton onClose={onClose} />
      </ModalContent>
    </ModalWrapper>,
    document.getElementsByTagName("body")[0]
  )
}

export default Modal
