const dark: string = "#201630"

export const colors = {
  sky: "#4ABCDB",
  purplePink: "#4B278A",
  purpleGray: "#323652",
  light: "#a685fb",
  main: "#5D7EE9",
  green: "#B7F22C",
  orange: "#F2A32C",
  dark,
  lightPink: "#D3BAFF",
  transparentDark: `${dark}8c`,
  lightText: "#fff",
  secondaryText: "#DFDFDF",
  darkText: "#253446",
  error: "#ff363a",
  success: "#5ed042",
  disabled: "#CAC8D2",
  purpleMain: "#2C1F42",
  yellow: "#FFCB00",
  contrastToYellow: "#302D39",
  black: "#000000",
  gray: "#EBEBEB",
  purple: "#201630",
  grayMedium: "#FFFFFF59",
  purpleThin: "#43446D",
  gamePage: {
    categoryLinkBg: "#392065",
    categoryLinkHover: "#4a2787",
    categoryLinkHoverBorder: "#ffcb02",
  },
}
