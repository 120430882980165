import React, { ReactNode } from "react"
import { MainContent as MainContentWrapper } from "./main-content-elements"

interface MainContentProps {
  children?: ReactNode
}

const MainContent = ({ children }: MainContentProps) => {
  return <MainContentWrapper>{children}</MainContentWrapper>
}

export { MainContent }
