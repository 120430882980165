import * as React from "react"
import { FunctionComponent, ReactElement, useRef, useState } from "react"
import styled from "styled-components"
import { AccountIcon } from "./AccountIcon"
import { AccountDropDown } from "./AccountDropDown"
import { colors } from "../core/colors"
import { Description, IDescription } from "../layout/Description"
import { media } from "../layout/theme"
import { BreakPoints } from "../layout/responsive"
import { ILayout, Row } from "../layout/Layout"
import { IAuthModel } from "../core/misk"
import { useDropDownAnimation } from "../core/hooks/useDropDownAnimation"
import { useAuth } from "../core/hooks/useAuth"
import { useOnClickOutside } from "../core/hooks/useOnClickOutside"
import { BorderBottomAnimationWrapper } from "../core/components/BorderBottomAnimationWrapper"
import { AlignItemsTypes, FontSizeTypes } from "../core/enums"
import Modal from "../../modal"
import RegistrationForm from "../../registration-form"
import { useTranslation } from "react-i18next"

function getAccountBg(visible: boolean, footer?: boolean): string {
  if (footer) {
    return "transparent"
  }
  return visible ? colors.purplePink : colors.dark
}

const Nickname = styled(Description)<IDescription>`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${media.lessThan(BreakPoints.smallDesktop)} {
    display: none;
  }
`

const ClickableRow = styled(Row)<ILayout>`
  cursor: pointer;
  height: 60px;
  padding: 0 27px;
  max-width: 280px;
`

interface IAccount {
  footer?: boolean
  shouldShowLogin?: boolean | null
}

function getAccountText(
  t: ((value: string) => string) | null,
  user: IAuthModel | null
): string {
  if (user && user.account && user.account.email) {
    return user.account.email
  }
  // TODO
  return "myAccount"
}

const Account: FunctionComponent<IAccount> = ({
  footer = false,
  shouldShowLogin,
}: IAccount): ReactElement => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState<boolean>(false)
  const animationType = useDropDownAnimation(visible)
  const ref = useRef(null)
  const { user } = useAuth()
  const [openedModal, openModal] = useState(false);

  useOnClickOutside(ref, (): void => setVisible(false))
  const userName = getAccountText(null, user)
  const bgColor = getAccountBg(visible, footer)

  if (shouldShowLogin === null || shouldShowLogin === undefined) {
    shouldShowLogin = true;
  }

  return (
    <Row componentWidth="auto">
      <BorderBottomAnimationWrapper removeBorder={visible}>
        <ClickableRow
          bg={bgColor}
          componentWidth="auto"
          ai={AlignItemsTypes.center}
          onClick={(): void => setVisible(!visible)}
          layoutRef={ref}
        >
          <AccountIcon mright="10px" />
          {shouldShowLogin ?
            <Nickname fontSize={FontSizeTypes.m}>
              {userName.toUpperCase()}
            </Nickname> :
            <div onClick={() => openModal(true)}>
              <Nickname fontSize={FontSizeTypes.m}>
                {t("register")}
              </Nickname>
            </div>
          }
          {shouldShowLogin && <AccountDropDown
            setVisible={setVisible}
            animationType={animationType}
            shouldShowLogin={shouldShowLogin}
            openModal={openModal}
          />}
        </ClickableRow>
      </BorderBottomAnimationWrapper>
      <Modal onClose={() => openModal(false)} opened={openedModal}>
        <RegistrationForm/>
      </Modal>
    </Row>
  )
}

export { Account }
