import { IHeaderLink } from "./headerLinks"
import { ListItem } from "./HeaderListItem"
import React from "react"
import { ListItemWithSub } from "./ListItemWithSub";

interface Props {
  data: IHeaderLink
  locale?: string
}

export function TopBarMenuItem({data, locale}: Props) {
  if (data.link) {
    return <ListItem to={data.link.url} text={data.text} locale={locale}/>;
  } else if (data.subMenu) {
    return <ListItemWithSub subMenu={data.subMenu} text={data.text} locale={locale} />;
  } else {
    return null;
  }
}
