import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import commonEn from "./en/common.json"
import commonDe from "./de/common.json"
import commonFr from "./fr/common.json"
import registrationEn from "./en/registration.json"
import registrationDe from "./de/registration.json"
import registrationFr from "./fr/registration.json"

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    react: {
      useSuspense: false,
    },
    detection: {
      order: ['path', 'querystring', 'subdomain', 'localStorage', 'cookie', 'sessionStorage', 'navigator', 'htmlTag'],
    },
    fallbackLng: "en",
    defaultNS: "common",
    supportedLngs: ["en", "de", "fr"],
    resources: {
      en: {
        common: commonEn,
        registration: registrationEn
      },
      de: {
        common: commonDe,
        registration: registrationDe
      },
      fr: {
        common: commonFr,
        registration: registrationFr
      },
    },
  })

export default i18n
