import React, { FunctionComponent, ReactElement, ReactNode } from "react"
import styled from "styled-components"
import { RenderLinks } from "./RenderLinks"
import { Account } from "./AccountComponent"
import { DownloadGlyph } from "./DownloadGlyph"
import { ILayout, Layout, Row } from "../layout/Layout"
import { media } from "../layout/theme"
import { BreakPoints } from "../layout/responsive"
import { TChannelTypes } from "../core/misk"
import { AlignItemsTypes, JustifyContentTypes } from "../core/enums"
import { NavLink } from "../core/components/NavLink"
import { ILocaleLink, Locales } from "../common/Locales"
import { FTopBarFragment } from "../../../../gatsby-graphql"
import { GatsbyImage } from "gatsby-plugin-image"

const StyledHeader = styled(Row)<ILayout>`
  ${media.lessThan(BreakPoints.desktop)} {
    display: none;
  }
`

export interface IHeaderElements {
  logo?: boolean
  menu?: boolean
  locales?: ILocaleLink[]
  account?: boolean
  downloadButton?: boolean
}

export interface IHeader {
  data: FTopBarFragment
  channel?: TChannelTypes
  title?: ReactNode
  show?: IHeaderElements
  locale?: string
}

const Header: FunctionComponent<IHeader> = ({
  data,
  title,
  show,
  channel,
                                              locale,
}: IHeader): ReactElement => {
  const {
    logo = true,
    menu = true,
    locales = [],
    account = true,
    downloadButton = true,
  } = show || {}

  let leftWidth = "auto"
  let centerWidth = ""
  let rightWidth = "100%"
  // to put title in center we should give left and right components same width, it's not necessary 33% but it's not clear how to calculate it more precise
  if (title) {
    leftWidth = centerWidth = rightWidth = "33%"
  }

  const logoHeight = 60;
  let logoWidth = data.logo?.localFile?.childImageSharp?.gatsbyImageData?.width / data.logo?.localFile?.childImageSharp?.gatsbyImageData?.height * logoHeight
  if (isNaN(logoWidth)) {
    logoWidth = 0;
  }

  return (
    <StyledHeader
      ai={AlignItemsTypes.center}
      componentHeight="60px"
      jc={JustifyContentTypes.spaceBetween}
    >
      <Row
        componentWidth={leftWidth}
        componentHeight="100%"
        ai={AlignItemsTypes.center}
      >
        {
          <NavLink href="/">
            <Layout componentHeight={logoHeight+'px'} componentWidth={logoWidth+'px'} jc={JustifyContentTypes.center}>
              <GatsbyImage alt={''} image={data.logo?.localFile?.childImageSharp?.gatsbyImageData} />
            </Layout>
          </NavLink>
        }
        {menu && <RenderLinks config={data.mainMenu} channel={channel} locale={locale} />}
      </Row>
      {title && (
        <Layout componentWidth={centerWidth} ai={AlignItemsTypes.center}>
          {title}
        </Layout>
      )}
      <Row
        componentWidth={rightWidth}
        ai={AlignItemsTypes.center}
        jc={JustifyContentTypes.flexEnd}
      >
        {data.languageSelector && <Locales locales={locales} />}
        {data.myAccount && (
          <Row componentWidth="auto">
            <Account shouldShowLogin={data.loginButton}/>
          </Row>
        )}
        {data.installGlyph && <DownloadGlyph />}
      </Row>
    </StyledHeader>
  )
}

export { Header }
