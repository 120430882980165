import React, { ReactNode } from "react"
import {
  HeadingH1,
  HeadingH2,
  HeadingH3,
  HeadingH4,
  HeadingH5,
  HeadingH6,
} from "./heading-elements"

interface HeadingProps {
  children: ReactNode
  textTransform?: string
}

const H1 = ({ children, textTransform }: HeadingProps) => {
  return <HeadingH1 textTransform={textTransform}>{children}</HeadingH1>
}

const H2 = ({ children, textTransform }: HeadingProps) => {
  return <HeadingH2 textTransform={textTransform}>{children}</HeadingH2>
}

const H3 = ({ children, textTransform }: HeadingProps) => {
  return <HeadingH3 textTransform={textTransform}>{children}</HeadingH3>
}

const H4 = ({ children, textTransform }: HeadingProps) => {
  return <HeadingH4 textTransform={textTransform}>{children}</HeadingH4>
}

const H5 = ({ children, textTransform }: HeadingProps) => {
  return <HeadingH5 textTransform={textTransform}>{children}</HeadingH5>
}

const H6 = ({ children, textTransform }: HeadingProps) => {
  return <HeadingH6 textTransform={textTransform}>{children}</HeadingH6>
}

const Heading = {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
}

export { Heading }
