import axios from "axios"

export function submit(email: string, password:string, ageS:string, subscription: boolean, showSuccess: () => void, showError: () => void) {
  const age = parseInt(ageS);
  const currentYear = new Date().getFullYear();
  const dob = `${currentYear - age}-12-31`;
  let opt = '';
  if (subscription) {
    opt = '<channel>TRION</channel>';
  }

  const registerURl =
    "https://glyph.trionworlds.com/api/v1_2/register-account.action?X-GameServer-Channel=1001";
  const requestData = `<?xml version="1.0" encoding="UTF-8"?>
      <accountCreateRequest version="1.2">
        <account>
          <firstName> </firstName>
          <lastName> </lastName>
          <dateOfBirth>${dob}</dateOfBirth>
          <emailAddress>${email}</emailAddress>
          <password>${password}</password>
          <languageCode>en_US</languageCode>
          <affiliateId></affiliateId>
          <optInChannels>${opt}</optInChannels>
          </account>
        </accountCreateRequest>`;

  axios.post(registerURl, requestData, {headers: { 'Content-Type': 'text/plain' }}).then(function (data) {
    var parser = new DOMParser();
    var doc = parser.parseFromString(data.data, "application/xml");
    const error = doc.getElementsByTagName('error');
    if (error.length) {
      throw '';
    }
    showSuccess();
  }).catch(function() {
    showError();
  });
}
