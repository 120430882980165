import styled from "styled-components"
import { Link } from "../link/link"
import {
  ButtonStyles,
  ButtonPrimaryStyles,
  ButtonSecondaryStyles,
  ButtonDisabledStyles,
  ButtonTransparencyStyles,
  TransparencyLinkStyles,
  ButtonXsStyles,
  ButtonSmStyles,
  ButtonMdStyles,
  ButtonLgStyles,
  ButtonXlStyles,
  ButtonDangerStyles,
} from "./button-styles"

export type ButtonType = "primary" | "secondary" | "danger" | "transparency" | "disabled" | "transparencyLink"
export type ButtonSize = "xs" | "sm" | "md" | "lg" | "xl"

interface IButton {
  type: ButtonType
  size: ButtonSize
  textTransform: string
  onClick?: () => void
  locale?: string
}

const ButtonTypeStyles = {
  primary: ButtonPrimaryStyles,
  secondary: ButtonSecondaryStyles,
  danger: ButtonDangerStyles,
  transparency: ButtonTransparencyStyles,
  disabled: ButtonDisabledStyles,
  transparencyLink: TransparencyLinkStyles
}
const getButtonTypeStyles = (type: ButtonType) => ButtonTypeStyles[type]

const ButtonSizeStyles = {
  xs: ButtonXsStyles,
  sm: ButtonSmStyles,
  md: ButtonMdStyles,
  lg: ButtonLgStyles,
  xl: ButtonXlStyles,
}
const getButtonSizeStyles = (size: ButtonSize) => ButtonSizeStyles[size]

export const Button = styled(Link)<IButton>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-transform: ${props =>
    props.textTransform ? props.textTransform : "none"};
  ${ButtonStyles}
  ${props => getButtonTypeStyles(props.type)}
  ${props => getButtonSizeStyles(props.size)}
`
