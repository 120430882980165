import { css } from "styled-components"
import { colors } from "../../helpers/colors"
import arrow from "../../assets/arrow.svg"
import { BreakPoints, media } from "../../helpers/rwd"
import { FontsIncludes } from "./fonts"

export const GlobalStyleStyles = css`
  ${FontsIncludes}
  body {
    color: ${colors.white};
    background: ${colors.darker};
  }

  p {
    margin: 15px 0;
  }

  // SLICK SLIDER
  .slick-slider {
    .slick-arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      border: 2px solid #785D1D;
      height: 61px;
      border-radius: 0;


      &:before {
        content: "";
        position: relative;
        font-size: 1px;
        display: block;
        width: 100%;
        height: 100%;
        background: url(${arrow}) no-repeat center center;
      }
    }

    .slick-slide {
      padding: 0 30px;
      ${media.max(BreakPoints.lgDesktop)} {
        padding: 0 26px;
      }
      ${media.max(BreakPoints.phone)} {
        padding: 0 6px;
      }
    }

    .slick-list {
      margin: 0;
    }

    .slick-arrow.slick-prev {
      left: -53px;
      ${media.max(BreakPoints.lgDesktop)} {
        right: -13px;
      }
      &:before {
        transform: rotate(90deg);
      }
    }

    .slick-arrow.slick-next {
      right: -53px;
      ${media.max(BreakPoints.lgDesktop)} {
        right: -35px;
      }
      &:before {
        transform: rotate(-90deg);
      }
    }

    ul.slick-dots {
      bottom: -56px;
      margin: 1px;

      li {
        width: 10px;
        button {
          background: none;

          &:before {
            opacity: 1;
            color: #FFCB00;
          }
        }

        &:hover {
          button {
            &:before {
              color: white;
            }
          }
        }

        &.slick-active {
          button {
            &:before {
              color: white;
            }
          }
        }
      }
    }
  }
`

export const GlobalWrapperStyles = css`
  background: ${colors.purpleMain};
`
