const Icons = {
  account: "/icon-account.svg",
  download: "/icon-dl.svg",
  cart: "/icon-cart.svg",
  defilement: "/icon-defilement.svg",
  expand: "/icon-expand.svg",
  languages: "/icon-languages.svg",
  rightArrow: "/icon-right-arrow.svg",
  search: "/search.svg",
  logo: "/logo.svg",
  showPw: "/show-pw.svg",
  check: "/check.svg",
  circleCheck: "/circle-check.svg",
  circleNotCheck: "/circle-not-check.svg",
  arrow: "/arrow.svg",
  glyphCoin: "/img/glyph-coin.png",
  binaryCoin: "/img/binary-coin.png",
  edit: "/edit-icon.svg",
  paypal: "/img/paypal.png",
  mastercard: "/img/mastercard.png",
  credit: "/img/credit.png",
  bucket: "/bucket.svg",
  backArrow: "/back-arrow.svg",
  connectAccount: "/connect-account.svg",
  warning: "/warning-icon.svg",
  success: "/success.svg",
  glyphGb: "/glyph-bg.svg",
  lock: "/lock.svg",
  burger: "/burger.svg",
  notification: "/notification-icon.svg",
}

export enum IconTypes {
  account = "account",
  download = "download",
  cart = "cart",
  defilement = "defilement",
  expand = "expand",
  languages = "languages",
  rightArrow = "rightArrow",
  search = "search",
  logo = "logo",
  showPw = "showPw",
  check = "check",
  circleCheck = "circleCheck",
  circleNotCheck = "circleNotCheck",
  binaryCoin = "binaryCoin",
  glyphCoin = "glyphCoin",
  edit = "edit",
  paypal = "paypal",
  mastercard = "mastercard",
  credit = "credit",
  bucket = "bucket",
  backArrow = "backArrow",
  connectAccount = "connectAccount",
  warning = "warning",
  success = "success",
  glyphGb = "glyphGb",
  lock = "lock",
  burger = "burger",
  notification = "notification",
}

export { Icons }
