import React, { ReactElement } from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { ISpaceTypes } from "../enums"
import { IconTypes } from "../Icons"
import { space } from "../../layout/theme"

export interface IIcon extends ISpaceTypes {
  alt: string
  icon: IconTypes
  height?: string
  width?: string
  className?: string
}

const StyledIcon = styled.img<any>`
  width: ${props => (props.width ? props.width : "auto")};
  height: ${props => (props.height ? props.height : "auto")};
  ${space}
`

const Icon = ({
  alt,
  icon,
  height,
  width,
  className,
  ...props
}: IIcon): ReactElement | null => {
  if (icon === IconTypes.logo) {
    return <Logo className={className} />
  }
  if (icon === IconTypes.languages) {
    return <Languages />
  }
  if (icon === IconTypes.defilement) {
    return <Defilement className={className} />
  }
  if (icon === IconTypes.burger) {
    return <Burger />
  }
  return null
}

function Logo({ className }: { className?: string }) {
  return (
    <StaticImage
      className={className}
      src="../../../../assets/logo-glyph.svg"
      alt="glyph"
    />
  )
}

function Languages() {
  return (
    <StaticImage
      src="../../../../assets/icons/icon-languages.svg"
      alt="languages"
      layout="fixed"
    />
  )
}

export function Defilement({ className }: { className?: string }) {
  return (
    <StaticImage
      className={className}
      src="../../../../assets/icons/icon-defilement.svg"
      alt="open-close"
      layout="fixed"
    />
  )
}

function Burger() {
  return (
    <StaticImage
      src="../../../../assets/icons/burger.svg"
      alt="open-close"
      layout="fixed"
    />
  )
}

export { Icon }
