import React, { PropsWithChildren, ReactElement } from "react"
import styled from "styled-components"
import { colors } from "../colors"
import { ILayout, Row } from "../../layout/Layout"
import { JustifyContentTypes } from "../enums"

interface IStyledBottomStick {
  removeBorder?: boolean
}

// The simplest way to create border animation from center to side, it's create a div against borders
const StyledBottomStick = styled.div<IStyledBottomStick>`
  height: 4px;
  position: absolute;
  bottom: 0;
  z-index: 10;
  background-color: ${({ removeBorder }: IStyledBottomStick): string =>
    removeBorder ? "transparent" : colors.yellow};
`

// On link hover animating our div(border) from 0 width to 100%, reverse for not:hover event
const StyledWrapper = styled(Row)<ILayout>`
  &:hover ${StyledBottomStick} {
    width: 100%;
    transition: 300ms width ease;
  }
  &:not(hover) ${StyledBottomStick} {
    width: 0;
    transition: 300ms width ease;
  }
`

interface IProps {
  removeBorder?: boolean
}

const BorderBottomAnimationWrapper = ({
  children,
  removeBorder = false,
}: PropsWithChildren<IProps>): ReactElement => {
  return (
    <StyledWrapper
      jc={JustifyContentTypes.center}
      componentWidth="100%"
      componentHeight="100%"
    >
      {children}
      <StyledBottomStick removeBorder={removeBorder} />
    </StyledWrapper>
  )
}

export { BorderBottomAnimationWrapper }
