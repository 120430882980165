import React, { ReactElement } from "react"
import styled from "styled-components"
import { Button, IButton } from "../form/Button"
import { colors } from "../core/colors"
import { IconTypes } from "../core/Icons"
import { Icon } from "../core/components/Icon"

interface IBurger {
  onClick: () => void
}

const ButtonContainer = styled(Button)<IButton>`
  width: 45px;
  button {
    height: 40px;
    border-bottom: 1px solid ${colors.yellow};
  }
`

const Burger = ({ onClick }: IBurger): ReactElement => {
  return (
    <ButtonContainer onClick={onClick} color={colors.yellow}>
      <Icon alt={IconTypes.burger} icon={IconTypes.burger} />
    </ButtonContainer>
  )
}

export { Burger }
