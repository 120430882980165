import React, { ReactNode } from "react"
import { ContentWrapper as Wrapper } from "./content-wrapper-elements"

interface ContentWrapperProps {
  children?: ReactNode
  maxWidth?: number
}

const ContentWrapper = ({ children, maxWidth }: ContentWrapperProps) => {
  return <Wrapper maxWidth={maxWidth}>{children}</Wrapper>
}

export { ContentWrapper }
