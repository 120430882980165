import React, {
  FunctionComponent,
  ReactElement,
  ReactNode,
  useRef,
} from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { Category, IGame, TChannelTypes } from "./core/misk"
import { ILayout, Row } from "./layout/Layout"
import { GlobalStyle } from "./layout/theme"
import { colors } from "./core/colors"
import { MobileHeader } from "./mobile/MobileHeader"
import { Header } from "./header/Header"
import { ILocaleLink } from "./common/Locales"
import { BreakPoints, media } from "../../helpers/rwd"
import { FTopBarFragment } from "../../../gatsby-graphql"

export interface IHeaderElements {
  logo?: boolean
  menu?: boolean
  locales?: ILocaleLink[]
  account?: boolean
  downloadButton?: boolean
}

export interface ITopBar {
  data: FTopBarFragment
  title?: ReactNode
  show?: IHeaderElements
  games?: IGame[]
  categories?: Category[]
  channel?: TChannelTypes
  locale?: string
}

const StyledHeaderContainer = styled(Row)<ILayout>`
  font-family: "Motiva-sans", sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
  margin: 0 auto;
  top: 0;
  left: 0;
  z-index: 20;
  animation-delay: 300ms;
  transform-origin: top center;

  border-bottom: 1px solid ${colors.darkText};
  padding: 0 0 0 55px;
`

const TopBar: FunctionComponent<ITopBar> = ({
  title,
  show,
  games,
  categories,
  channel,
  data,
                                              locale,
}: ITopBar): ReactElement => {
  const headerRef = useRef(null)
  // TODO useOpacityHover(headerRef.current, "20")
  const { t } = useTranslation()

  return (
    <StyledHeaderContainer layoutRef={headerRef} bg={colors.dark}>
      <GlobalStyle />
      <MobileHeader
        data={data}
        games={games || []}
        channel={channel}
        categories={categories || []}
        locales={show?.locales || []}
        showSearch={false}
      />
      <Header
        data={data}
        channel={channel}
        locale={locale}
        title={title}
        show={show}
      />
    </StyledHeaderContainer>
  )
}

export default TopBar
