export const media = {
  max: (breakPoint: BreakPoints): string =>
    `@media(max-width: ${breakPoint}px)`,
  min: (breakPoint: BreakPoints): string =>
    `@media(min-width: ${breakPoint + 1}px)`,
}

export enum BreakPoints {
  lgDesktop = 1440,
  desktop = 1279,
  smallDesktop = 992,
  tablet = 768,
  phone = 480,
}
