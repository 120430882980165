import { css } from "styled-components"

export const inputStyle = css`
  font: 400 15px/18px "Kanit", sans-serif;
  color: #fff;
  background: #160F22;
  border: 1px solid #FFCB00;
`

export const errorStyles = css`
   color: #a00;
`

export const buttonStyles = css`
  background: #FFCB00;
  border: 1px solid #FFCB00;
  font: 500 22px/25px "Kanit", sans-serif;
  color: #1F1630;
`

export const labelStyles = css`
  color: #fff;
`

export const StatusWrapperStyles = css`
  font-size: 20px;
`;

export const RegisterFormStyles = css`
  border: 1px solid #FFCB00;
  background: #201630;
`

export const PassErrorTitleStyles = css`
   color: #fff;
`

export const PasswordErrorsListStyles = css`
   background: #0C0913;
`

export const CheckboxInputLabelAfterStyles = css`
  border: solid #fcfcfc;
  border-width: 0 3px 3px 0;
`

export const CheckboxLabelBeforeStyles = css`
  border: 1px solid #FFCB00;
  background-color: #00101d;
`

export const CheckboxLinkStyles = css`
  color: #FFCB00;
  text-decoration: none;
`

export const CheckboxContainerLabelStyles = css`
  font-size: 12px;
`

export const SuccessColor = '#6bc589';
export const ErrorColor = '#a00';

