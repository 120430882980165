const scaleYOpenAnimation = `
@keyframes growDownOpen {
    0% {
        transform: scaleY(0);
    }
    80% {
        transform: scaleY(1.1);
    }
    100% {
        transform: scaleY(1);
    }
}`

const scaleYCloseAnimation = `
@keyframes growDownClose {
    0% {
        transform: scaleY(1);
    }
    80% {
        transform: scaleY(1.1);
    }
    100% {
        transform: scaleY(0);
    }
}`

const callScaleYOpen = (): string => `
    animation: growDownOpen 300ms ease-in-out forwards;
    transform-origin: top center;
    ${scaleYOpenAnimation}
`

const callScaleYClose = (): string => `
    animation: growDownClose 300ms ease-in-out forwards;
    transform-origin: top center;
    ${scaleYCloseAnimation}
`

export {
  scaleYOpenAnimation,
  scaleYCloseAnimation,
  callScaleYOpen,
  callScaleYClose,
}
