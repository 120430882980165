import React, { PropsWithChildren, ReactElement } from "react"
import styled from "styled-components"
import { ISpaceTypes } from "../enums"
import { space } from "../../layout/theme"
import { LinkUrl, useAuth } from "../hooks/useAuth"

// TODO its from next
interface LinkProps {
  href:
    | string
    | {
        pathname: string
        query: any
      }
  as?: string
}

export interface INavLinkModel extends LinkProps {
  external?: boolean
  withAuth?: boolean
  locale?: string
}

export type INavLink = INavLinkModel & ISpaceTypes

const StyledLink = styled.a<ISpaceTypes>`
  ${space}
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`

function getWithAuthHref(
  href: LinkUrl,
  isAuth: boolean,
  withAuth?: boolean
): LinkUrl {
  if (withAuth && !isAuth) {
    return "/login"
  }
  return href
}

const NavLink = ({
  href,
  as,
  children,
  external,
  withAuth = false,
                   locale,
  ...props
}: PropsWithChildren<INavLink>): ReactElement => {
  const { user } = useAuth()
  const withAuthHref = getWithAuthHref(href, !!user, withAuth) as string
  if (external) {
    return (
      <StyledLink href={withAuthHref} hrefLang={locale ? locale : "en"} {...props}>
        {children}
      </StyledLink>
    )
  }

  // TODO <Link href={withAuthHref} as={as}>
  return (
    <StyledLink href={withAuthHref} hrefLang={locale ? locale : "en"} {...props}>
      {children}
    </StyledLink>
  )
}

export { NavLink }
