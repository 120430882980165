import { css } from "styled-components"
import { blueEllipseGradient, colors } from "../../helpers/colors"
import { mozillaFontAlignFix } from "@gamigo/gatsby-theme-strapi/src/helpers/mozilaFontAlignFix"

export const ButtonStyles = css``

export const ButtonPrimaryStyles = css`
  background: ${colors.yellow};
  color: ${colors.darker};
  position: relative;
  //display:block;
  overflow:hidden;
  z-index: 1;
  cursor: pointer;
  transition:         0.08s ease-in;

  &:before {
    content: "";
    position: absolute;
    background: ${colors.white};
    bottom: 0;
    left: 0;
    right: 0;
    top: 100%;
    z-index: -1;
    -webkit-transition: top 0.09s ease-in;
  }

  &:hover:before {
    top: 0;
  }
`

export const ButtonSecondaryStyles = css`
  background: none;
  color: ${colors.white};
  border: 1px solid ${colors.yellow};
  position: relative;
  //display:block;
  overflow:hidden;
  width: 200px;
  height: 48px;
  z-index: 1;
  cursor: pointer;
  transition:         0.08s ease-in;

  &:before {
    content: "";
    background: ${colors.white};
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 100%;
    z-index: -1;
    -webkit-transition: top 0.09s ease-in;
  }

  &:hover{
    color: ${colors.darker};
    :before {
      top: 0;
    }
  }
`
export const ButtonTransparencyStyles = css`
  background: none;
  color: ${colors.white};
  border: 1px solid ${colors.yellow};
  position: relative;
  //display:block;
  overflow:hidden;
  z-index: 1;
  cursor: pointer;
  width: 200px;
  height: 48px;
  transition:         0.08s ease-in;

  &:before {
    content: "";
    background: ${colors.white};
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 100%;
    z-index: -1;
    -webkit-transition: top 0.09s ease-in;
  }

  &:hover{
    color: ${colors.darker};
    :before {
      top: 0;
    }
  }
`
export const TransparencyLinkStyles = css`
  background: none;
  color: ${colors.yellow};
  border: none;
  position: relative;
  display:inline-block;
  overflow:hidden;
  z-index: 1;
  margin: 0;
  padding: 9px 0 1px !important;
  min-height: auto !important;
  cursor: pointer;
  transition:         0.08s ease-in;

  &:hover{
    color: ${colors.white};
    :before {
      top: 0;
    }
  }
`
export const ButtonDisabledStyles = css`
  background: #A7A7A7;
  color: ${colors.darker};
  position: relative;
  //display:block;
  overflow:hidden;
  z-index: 1;
  cursor: default;
  width: 200px;
  height: 48px;
`

export const ButtonXsStyles = css``
export const ButtonSmStyles = css``
export const ButtonMdStyles = css`
  min-width: 200px;
  min-height: 48px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 10px 13px 10px 13px;
  ${mozillaFontAlignFix} {
    padding: 12px 13px 10px 13px;
  }
`
export const ButtonLgStyles = css``
export const ButtonXlStyles = css``
