import styled, { createGlobalStyle } from "styled-components"
import { GlobalStyleStyles, GlobalWrapperStyles } from "./layout-styles"
import { colors } from "../../helpers/colors"
import { fonts } from "../../helpers/fonts"

export const GlobalStyle = createGlobalStyle`
  body {
    position: relative;
    color: ${colors.black};
    background: ${colors.white};
    font-family: ${fonts.families.default};
    font-weight: ${fonts.weights.default};
    font-size: ${fonts.sizes.default};
  }
  *,
  :after,
  :before {
    box-sizing: border-box;
    scroll-behavior: smooth;
  }
  a {
    text-decoration: none;
    color: ${colors.white};
  }
  ${GlobalStyleStyles}
`

export const GlobalWrapper = styled.div`
  max-width: 2500px;
  margin: 0 auto;
  ${props => props.theme}
  ${GlobalWrapperStyles}
`
