import { css } from "styled-components"
import { colors } from "../../helpers/colors"
import { fonts } from "../../helpers/fonts"

export const HeadingCommonStyles = css`
  color: ${colors.white};
  font-weight: ${fonts.weights.medium};
`

export const HeadingH1Styles = css`
  text-shadow: 2px 2px 5px black;
`
export const HeadingH2Styles = css``
export const HeadingH3Styles = css`
  font-size: 1.0625rem;
  line-height: 1.2rem;
`
export const HeadingH4Styles = css``
export const HeadingH5Styles = css``
export const HeadingH6Styles = css``
