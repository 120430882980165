import styled from "styled-components"
import React, { FunctionComponent, ReactElement } from "react"
import { Collapse } from "./Collapse"
import { MobileAccount } from "./MobileAccount"
import { Column, ILayout, Layout, Row } from "../layout/Layout"
import { colors } from "../core/colors"
import { Description } from "../layout/Description"
import { AlignItemsTypes, JustifyContentTypes } from "../core/enums"
import { ILocaleLink, Locales } from "../common/Locales"
import { GatsbyImage } from "gatsby-plugin-image"
import { FTopBarFragment } from "../../../../gatsby-graphql"
import { IHeaderLink, ITopBarSubMenu } from "../header/headerLinks"
import { NavLink } from "../core/components/NavLink"
import { IconTypes } from "../core/Icons"
import { Icon, IIcon } from "../core/components/Icon"

export const ListItem = styled(Row)<ILayout>`
  border-bottom: 1px solid ${colors.grayMedium};
`

const GameList = styled(Column)<ILayout>`
  max-height: 210px;
  overflow: auto;
  background: #32344B;
`

const GameName = styled(Row)`
  font-size: 12px;
  margin: 6px 0;
`

interface IMobileMenu {
  data: FTopBarFragment
  locales: ILocaleLink[]
}

export enum MenuPosition {
  left = "left",
  right = "right",
}

export function getMenuStyleByPosition(
  position: MenuPosition,
  visible: boolean
): string {
  if (position === MenuPosition.right) {
    return `right: ${visible ? "0" : "-70%"};`
  }
  return `left: ${visible ? "0" : "-70%"};`
}

const MobileMenu: FunctionComponent<IMobileMenu> = ({
  data,
  locales,
}: IMobileMenu): ReactElement => {
  const logoHeight = 60;
  let logoWidth = data.logo?.localFile?.childImageSharp?.gatsbyImageData?.width / data.logo?.localFile?.childImageSharp?.gatsbyImageData?.height * logoHeight
  if (isNaN(logoWidth)) {
    logoWidth = 0;
  }

  return (
    <Column>
      <Row
        componentHeight="50px"
        componentWidth="auto"
        padding="0 15px"
      >
        <Layout componentHeight={logoHeight+'px'} componentWidth={logoWidth+'px'} jc={JustifyContentTypes.center}>
          <GatsbyImage alt={'menu'} image={data.logo?.localFile?.childImageSharp?.gatsbyImageData} />
        </Layout>
      </Row>
      <Column mtop="30px">
        {data.mainMenu.map((item: IHeaderLink) =>
          <MobileMenuItem data={item} key={item.id}/>
        )}
      </Column>
      <Column mtop="50px" padding="0 15px">
        {data.myAccount && <MobileAccount shouldShowLogin={data.loginButton}/>}
        {data.languageSelector && <Row jc={JustifyContentTypes.center} mtop="30px">
          <Locales locales={locales} short={false} />
        </Row>}
      </Column>
    </Column>
  )
}

interface Props {
  data: IHeaderLink
}

const SubMenuImg = styled.img`
  max-height: 25px;
  height: auto;
  width: auto;
  margin-right: 14px;
`

interface IDefilement extends IIcon {
  visible: boolean
}

const Defilement = styled(Icon)<IDefilement>`
  transition: all 0.4s ease;
  transform: ${(props: IDefilement): string =>
  props.visible ? "rotate(180deg)" : "rotate(0deg)"};
`

export function MobileMenuItem({data}: Props) {
  if (data.link) {
    return <ListItem
      ai={AlignItemsTypes.center}
      padding="0 15px"
      componentHeight="40px"
    >
      <NavLink href={data.link.url}>
        <Description uppercase>{data.text.toUpperCase()}</Description>
      </NavLink>
    </ListItem>;
  } else if (data.subMenu) {
    return <Collapse
      heading={(visible: boolean): ReactElement => (
        <ListItem
          ai={AlignItemsTypes.center}
          padding="0 15px"
          componentHeight="40px"
        >
          <Description uppercase>{data.text.toUpperCase()}</Description>
          <Defilement
            visible={visible}
            mleft="5px"
            alt={IconTypes.defilement}
            icon={IconTypes.defilement}
          />
        </ListItem>
      )}
      icon={(): false => false}
    >
      <GameList padding="10px 25px">
        {data.subMenu.map((subItem: ITopBarSubMenu, index: number): ReactElement => {
          return (
            <Column
              key={index}
            >
                <NavLink href={subItem.link.url}>
                  <GameName ai={AlignItemsTypes.center}>
                    <SubMenuImg alt={subItem.asset?.name ? subItem.asset?.name : ""} src={subItem.asset?.url} />
                    {subItem.text}
                  </GameName>
                </NavLink>
            </Column>
          )
        })}
      </GameList>
    </Collapse>;
  } else {
    return null;
  }
}

export { MobileMenu }
