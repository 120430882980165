import React, { FormEvent, useEffect, useState } from "react"
import { FormErrors, PassValidation, passwordValidation, validate } from "./validation"
import { useTranslation } from "react-i18next"
import i18next from "i18next"
import {
  Age,
  AgeContainer, AgeError,
  CheckboxContainer, CheckboxContainerLabel, CheckboxInput, CheckboxLabel, CheckboxLink,
  RegistrationInput,
  EmailError,
  Wrapper,
  Label, PassErrorMessage,
  PassErrorTitle,
  PassFormGroup,
  PasswordContainer,
  PasswordErrors,
  PasswordErrorsList,
  RegFormGroup,
  RegisterForm,
  RegistrationButton,
  RegSubmitContainer,
  StatusWrapper,
  TosError, Form, ImageWrapper, RegHeader, TextContainer, PasswordError
} from "./registration-form-elements"
import { submit } from "./submit"
import glyphLogo from "../../assets/glyph_logo.png"
import axios from "axios"

export default function RegistrationForm() {
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [age, setAge] = useState('');
  const [tos, setTos] = useState(false);
  const [subscription, setSubscription] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<FormErrors>({});
  const [passFocus, setPassFocus] = useState(false);
  const [passValidation, setPassValidation] = useState<PassValidation>(passwordValidation(''));

  useEffect(() => {
    axios.get('https://geoip.trionworlds.com/json').then(res => {
      if (res.data.region === 'US') {
        setSubscription(true);
      }
    });
  });

  const {t} = useTranslation('registration');

  let langSuffix = '';
  if (i18next.language !== 'en') {
    langSuffix = `-${i18next.language}`
  }


  const tosLink = `/${i18next.language}/terms-of-use${langSuffix}`;
  const ppLink = `/${i18next.language}/privacy-policy${langSuffix}`;

  const onSubmit=(e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errors = validate(email, pass, age, tos);
    if (errors.email || errors.password || errors.age || errors.tos) {
      setErrors(errors);
    } else {
      setLoading(true);
      submit(
        email,
        pass,
        age,
        subscription,
        () => {
          setSuccess(true);
          setLoading(false);
        },
        () => {
          setError(true);
          setLoading(false);
        }
      );
    }
  };

  return <RegisterForm>
    {success && <StatusWrapper>{t("success")}</StatusWrapper>}
    {error && <StatusWrapper>{t("fail")}</StatusWrapper>}
    {loading && <StatusWrapper>Loading...</StatusWrapper>}
    {(!success && !error && !loading) &&
    <Form onSubmit={onSubmit}>
      <ImageWrapper >
        <img src={glyphLogo} alt={'Glypg'}/>
      </ImageWrapper>
      <RegHeader>{t("create your glyph account")}</RegHeader>
      <RegFormGroup>
        <Wrapper>
          <Label htmlFor="email">{t("email")}</Label>
          <RegistrationInput type="email" id="email" name="email" required value={email} onChange={e => setEmail(e.target.value)}/>
          {errors.email && <EmailError>{t("emailError")}</EmailError>}
        </Wrapper>
      </RegFormGroup>
      <PassFormGroup>
        <PasswordContainer>
          <Wrapper>
            <Label htmlFor="password">{t("password")}</Label>
            <RegistrationInput onFocus={() => setPassFocus(true)} onBlur={() => setPassFocus(false)} type="password" id="password" name="password" required value={pass} onChange={e => {
              setPassValidation(passwordValidation(e.target.value));
              setPass(e.target.value)}
            }/>
            {errors.password && <PasswordError>{t("passError")}</PasswordError>}
          </Wrapper>
        </PasswordContainer>
        <AgeContainer>
          <Label htmlFor="age">{t("age")}</Label>
          <Age type="number" id="age" name="age" required min="1" max="99" value={age} onChange={e => setAge(e.target.value)}/>
          {errors.age && <AgeError>{t("ageError")}</AgeError>}
        </AgeContainer>
        {passFocus && <PasswordErrors>
          <PassErrorTitle hasError={passValidation.upper || passValidation.lower || passValidation.special || passValidation.length || false}>
            {t("passRules")}
          </PassErrorTitle>
          <PasswordErrorsList>
            <li>
              <PassErrorMessage isValid={!passValidation.upper}>{t("passRule1")}</PassErrorMessage>
            </li>
            <li>
              <PassErrorMessage isValid={!passValidation.lower}>{t("passRule2")}</PassErrorMessage>
            </li>
            <li>
              <PassErrorMessage isValid={!passValidation.special}>{t("passRule3")}</PassErrorMessage>
            </li>
            <li>
              <PassErrorMessage isValid={!passValidation.length}>{t("passRule4")}</PassErrorMessage>
            </li>
          </PasswordErrorsList>
        </PasswordErrors>}
      </PassFormGroup>
      <Wrapper>
        <CheckboxContainer>
          <Wrapper>
            <CheckboxInput type="checkbox" id="tos" name="tos" checked={tos} onChange={e => setTos(e.target.checked)}/>
            <CheckboxLabel htmlFor="tos">
              {t("tos1")}&nbsp;
              <CheckboxLink href={tosLink} target="_blank">{t("tos2")}</CheckboxLink>
              &nbsp;{t("tos3")}&nbsp;
              <CheckboxLink href={ppLink} target="_blank">{t("tos4")}</CheckboxLink>
              {t("tos5")}
            </CheckboxLabel>
          </Wrapper>
          {errors.tos && <TosError>{t("tosError")}</TosError>}
        </CheckboxContainer>
        <CheckboxContainer>
          <Wrapper>
            <CheckboxInput type="checkbox" id="subscription" name="subscription" checked={subscription} onChange={e => setSubscription(e.target.checked)}/>
            <CheckboxLabel htmlFor="subscription">
              {t("subscription_text")}
            </CheckboxLabel>
          </Wrapper>
        </CheckboxContainer>
      </Wrapper>
      <RegSubmitContainer>
        <RegistrationButton type="submit">
          {t("reg")}
        </RegistrationButton>
      </RegSubmitContainer>
      <Wrapper>
        <TextContainer>
          <Wrapper>
            <CheckboxContainerLabel htmlFor="news">
              {t("news")} <CheckboxLink href={ppLink} target="_blank">{t("newsLinkText")}</CheckboxLink>.
            </CheckboxContainerLabel>
          </Wrapper>
        </TextContainer>
      </Wrapper>
    </Form>}
  </RegisterForm>
}
