import "@fontsource/kanit/300.css"
import "@fontsource/kanit/500.css"

export const fonts = {
  families: {
    default: `"Motiva-sans", sans-serif`,
    motiva: '"Roboto", sans-serif',
  },
  weights: {
    default: 500,
    light: 300,
    bold: 800,
    medium: 500,
  },
  sizes: {
    1: "1.0625em", // 17px
    2: "1.125em", // 18px
    3: "1.25em", // 20px
    4: "1.5em", // 24px
    5: "1.625em", // 26px
    6: "2.25em", // 36px
    7: "2.5em", // 40px
    8: "5em", // 80px
    default: "16px",
    xs: "14px",
    s: "16px"
  },
}
