import React, { ReactNode, useEffect } from "react"
import i18n from "i18next"
import "../../i18n"
import { I18nextProvider } from "react-i18next"
import { FlattenSimpleInterpolation } from "styled-components"
import TopBar from "../top-bar"
import { ILocaleLink } from "../top-bar/common/Locales"
import { GlobalStyle, GlobalWrapper } from "./layout-elements"
import { FSeo_StrapiHomepageSeo_Fragment, FTopBarFragment } from "../../../gatsby-graphql"
import { GmgAssistant } from "../../helpers/gmg"
import { Helmet } from "react-helmet"
import { externalIncludes } from "./external-includes"

interface ILayout {
  children: ReactNode
  topBarEnabled?: boolean
  locales?: ILocaleLink[]
  className?: string
  additionalStyles?: FlattenSimpleInterpolation | null
  topBar?: FTopBarFragment | null
  seo?: FSeo_StrapiHomepageSeo_Fragment
  seoTitle?: string
  locale?: string
}

const Layout = ({
                  children,
                  topBar,
                  topBarEnabled = true,
                  locales = [],
                  className = "",
                  additionalStyles = null,
                  seoTitle = "",
                  locale,
                  seo
                }: ILayout) => {
  useEffect(() => {
    GmgAssistant.sendEvent()
  }, [])

  return (
    <I18nextProvider i18n={i18n}>
      <GlobalStyle />
      <Helmet>
        <title>{seo?.metaTitle}</title>
        <meta name="description" content={seo?.metaDescription || ''} />
        { locales.map((locale) => (
          <link key={locale.code} rel="alternate" href={`pages-preview-glyph.dev.glyph.gamigo.io/${locale.code}${seoTitle}`} hrefLang={locale.code} />
        ))
        }
        {
          externalIncludes.map((externalInclude) => (
            externalInclude.crossorigin
              ? <link key={externalInclude.href} rel={externalInclude.rel} href={externalInclude.href} crossorigin hrefLang={locale} />
              : <link key={externalInclude.href} rel={externalInclude.rel} href={externalInclude.href} hrefLang={locale}/>
          ))
        }
      </Helmet>
      <GlobalWrapper className={className} theme={additionalStyles}>
        {topBarEnabled && topBar && (
          <TopBar
            data={topBar}
            locale={locale}
            show={{
              locales: locales,
              account: false
            }}
          />
        )}
        {children}
      </GlobalWrapper>
    </I18nextProvider>
  )
}

export default Layout
